<div class="dados home">
    <section>
        <h4>Transporte Vertical ( Içamentos )</h4>
        <p class="clear bg">
            Quando executamos um içamento, nossa responsabilidade não se limita apenas com o móvel içado. Este também envolve tudo a sua volta, como jardins, gradil, grades, sacadas, fachada do prédio e principalmente crianças, que pela sua natural curiosidade tentam
            se aproximar.
        </p>
        <div class="left">
            <div class="video">
                <video controls>
                    <source src="assets/video/icamento.mp4" type="video/mp4">
                    <source src="assets/video/icamento.ogg" type="video/ogg">
                </video>
            </div>
        </div>
        <div class="right">
            <p>
                <img src="assets/img/home.jpg" alt="Casarão Mudanças" class="floatR"> A Casarão Mudanças Ltda está no mercado há 35 anos, se especializou em içamento, tornando os mais árduos serviços da mudança em algo corriqueiro. Desenvolvemos técnicas
                e equipamentos diversificados (Manuais, Mecânicos e Elétricos), e aplicados de acordo com a necessidade de cada elevação. Segurança: ter uma empresa contratada que se responsabilize por todo processo de içamento facilita a vida do Síndico
                e do Condômino. Recomendação: Nunca deixe pessoas sem equipamentos de segurança (capacetes, cintos de segurança, botas, etc.) executarem qualquer tipo de içamento.
            </p>
        </div>
        
       
    </section>
</div>