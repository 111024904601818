<div class="dados servicos">
    <section>
        <h4>Serviços</h4>
        <p class="clear"><span class="subtitle"> Nossos serviços prestados.</span></p>
        <p class="clear bg">
            Nós temos os menores custos do mercado, oferecemos nossos serviços a preços especiais a empresas conceituadas do mercado e ficaremos honrados em poder demonstrar nossa capacidade técnica de transporte.<br />
        </p>
        <p></p>
        <p>
            <img src="assets/img/servicos.jpg" alt="Casarão Mudanças" class="floatR"> &bull; Guinchos - todos elétricos com capacidade de 800 a 2.000 mil quilos.<br /> &bull; Guinchos especial - para apartamentos de cobertura, lança longa com 7.00 metros
            de altura.<br /> &bull; Ventosa Elétrica - capacidade de 600 quilos.<br /> &bull; Palheteira - capacidade de 3.000 quilos.<br /> &bull; Fornecemos engradados especiais para: Vidros,espelhos e pedras mármores.<br /> &bull; Içamentos - Guinchos
            com capacidade de 18 toneladas, talha, tiffor, cabos de aço, cordas e cantoneiras.<br /> &bull; Frota própria - Caminhões Baú, Munch, Peruas Kombi fechadas e abertas, todos eq<br/> &bull; Embalagens - Caixas de papelão, plásticas, polibolha,
            papel de seda, papel kraft, fitas adesivas, porta cabideiro e engradados especiais.<br /> &bull; Pessoal - Embaladores, marceneiros, motoristas, ajudantes, fiscais, eletricistas, todos capacitados.<br/> &bull; Transporte - Equipes especializadas
            em transporte, remoções, içamento de cofres, pianos, computadores, máquinas e cargas em geral.
        </p>
    </section>
</div>