<div class="dados local">
    <section>
        <h4>Localização</h4>
        <p class="clear">
            <span class="subtitle">
                R. ANTONIO AUGUSTO DE BARROS, 26 - Bairro: Cachoeirinha<br />
                Cep: 02863-100 - SÃO PAULO - SP
            </span>
        </p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.6224598041836!2d-46.67442578554542!3d-23.474079064258703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef9d84709d765%3A0x105f1db23be74930!2sR.%20Ant%C3%B4nio%20Augusto%20de%20Barros%2C%2026%20-%20Brasil%C3%A2ndia%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2002863-100!5e0!3m2!1spt-BR!2sbr!4v1603729027047!5m2!1spt-BR!2sbr" width="" height="500" frameborder="1" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </section>
</div>