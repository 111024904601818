<header>
    <div class="bg">
        <div class="header">
            <app-nav></app-nav>
            <h1><a routerLink="/home" routerLinkActive="active" class="zoom">Casarão Mudanças</a></h1>
            <div class="telefone">
                Tel.: +55 11 3851-6125 - Whatsapp  +55 11 97235-8220
             </div>
        </div>
    </div>
</header>