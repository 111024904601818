<!-- https://formspree.io/ -->
<div class="dados contatos">
    <section>
        <h4>Contatos</h4>
        <p class="clear"><span class="subtitle"> Entre em contato através deste formulário.</span></p>

        <!--<form action="https://formspree.io/f/xvovajln" method="POST">-->
            <form action="http://www.casaraomudancas.com.br/form.php" method="POST">
            <label>Nome</label>
            <input type="text" name="nome" required /><br />
            <label>E-mail</label>
            <input type="text" name="email" required /><br />
            <label>Mensagem</label>
            <textarea name="mensagem" required></textarea><br />
            <input type="submit" value="Enviar" class="submit" />
        </form>
    </section>
</div>
