<div class="dados fotos">
    <section>
        <h4>Fotos</h4>
        <p class="clear"><span class="subtitle">Galeria de imagens</span></p>
        <div class="container">
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/01.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/03.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/04.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/05.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/06.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/07.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/08.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/09.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/10.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/11.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/12.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/13.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/14.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/15.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/16.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/17.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/18.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/19.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
            <div class="trocar">
                <div class="front">
                    <a class="zoom"><img src="assets/img/fotos/20.jpg" alt="Casarão Mudanças"></a>
                </div>
            </div>
        </div>

    </section>
</div>